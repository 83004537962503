<template>
    <div>
        <HRetribusi imageLogo="imageLogo" />
        <!-- Panel -->
        <panel
            title="Profil Wajib Pajak Provinsi"
            class="panel panel-danger"
            v-if="user.npwpd_id"
        >
            <!-- <template slot="header">
				<h6 class="font-weight-bold mr-auto">profil Perusahaan Wajib Pajak Provinsi</h6>
			</template> -->
            <b-alert variant="warning" show class="text-center">
                <div class="h5 font-weight-bold">
                    Jika ada data yang tidak sesuai, silahkan hubungi
                    Administrator Pajak Daerah.
                </div>
            </b-alert>
            <br />
            <div id="wajibPajak">
                <b-card>
                    <b-tabs
                        pills
                        nav-class="mb-3"
                        content-class="rounded bg-white mb-4"
                        v-if="profil"
                    >
                        <b-tab active>
                            <template slot="title">
                                <span class="d-sm-none">Pills 1</span>
                                <span class="d-sm-block d-none"
                                    >Profil NPWPD</span
                                >
                            </template>
                            <b-table-simple
                                bordered
                                small
                                responsive
                                v-if="profil"
                            >
                                <b-th colspan="6">
                                    <h4>IDENTITAS WAJIB PAJAK DAERAH</h4>
                                </b-th>
                                <b-tr>
                                    <b-td width="200px">Nama Perusahaan</b-td>
                                    <b-td class="font-weight-bold">
                                        <span>
                                            {{ profil.nama }}
                                        </span>
                                    </b-td>
                                    <!-- <b-td
                                        class="font-weight-bold text-center"
                                        v-if="profil.status_registrasi_id === 5"
                                        ><b-button
                                            variant="outline-danger"
                                            size="sm"
                                            ><i class="fa fa-file-pdf"></i>
                                            Lihat NPWPD Elektronik</b-button
                                        ></b-td
                                    > -->
                                </b-tr>
                                <b-tr>
                                    <b-td>Nomor Induk</b-td>
                                    <b-td
                                        colspan="5"
                                        class="font-weight-bold"
                                        >{{ profil.no_induk }}</b-td
                                    >
                                </b-tr>
                                <b-tr v-if="profil.status_registrasi_id === 5">
                                    <b-td>Nomor Pokok</b-td>
                                    <b-td
                                        colspan="5"
                                        class="font-weight-bold"
                                        >{{ profil.no_pokok }}</b-td
                                    >
                                </b-tr>
                                <b-tr>
                                    <b-td>Nomor Handphone</b-td>
                                    <b-td colspan="5">{{ profil.nomp }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>Nomor Telepon</b-td>
                                    <b-td colspan="5">{{
                                        profil.notelepon
                                    }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>Email</b-td>
                                    <b-td colspan="5">
                                        {{ profil.email }}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td width="150px">Alamat</b-td>
                                    <b-td colspan="5">
                                        <div v-if="profil">
                                            {{ profil.alamat }},
                                            Kel. {{ profil.wilayah_kelurahan.nama }},
                                            Kec. {{ profil.wilayah_kelurahan.wilayah_kecamatan.nama }},
                                            {{ profil.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota.nama }},
                                            {{ profil.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota.wilayah_provinsi.nama }},
                                            {{ profil.kodepos }}
                                        </div>
                                    </b-td>
                                </b-tr>
                                <b-tr v-if="profil.bentuk_badan !== null">
                                    <b-td width="150px">Bentuk Badan</b-td>
                                    <b-td colspan="5">
                                        <span>
                                            {{ profil.bentuk_badan.nama }}
                                        </span>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td colspan="6" height="20px"></b-td>
                                </b-tr>
                                <b-th colspan="6">
                                    <h4>Data Registrasi Wajib Pajak</h4>
                                </b-th>
                                <b-tr>
                                    <b-td>
                                        Nomor Registrasi
                                    </b-td>
                                    <b-td
                                        v-if="profil.no_registrasi"
                                        class="font-weight-bold"
                                    >
                                        {{ profil.no_registrasi }}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        Tanggal Registrasi
                                    </b-td>
                                    <b-td v-if="profil.tgl_registrasi">
                                        {{ profil.tgl_registrasi }}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        Status Registrasi
                                    </b-td>
                                    <b-td v-if="profil">
                                        <div v-if="profil.status_registrasi_id === 5">
                                            <span
                                                class="label label-success"
                                                style="font-size: 11px;"
                                            >
                                                {{
                                                    profil.status_registrasi
                                                        .nama
                                                }}
                                            </span>
                                        </div>
                                        <div v-else-if="profil.status_registrasi_id < 5 && !(profil.status_registrasi_id === 4)">
                                            <span
                                                class="label label-warning"
                                                style="font-size: 11px;"
                                            >
                                                {{
                                                    profil.status_registrasi
                                                        .nama
                                                }}
                                            </span>
                                        </div>
                                        <div v-else>
                                            <span
                                                class="label label-danger"
                                                style="font-size: 11px;"
                                            >
                                                {{
                                                    profil.status_registrasi
                                                        .nama
                                                }}
                                            </span>
                                        </div>
                                    </b-td>
                                </b-tr>
                            </b-table-simple>
                        </b-tab>
                        <b-tab
                            v-if="
                                profil.jenis < 41 && profil.pimpinan.aktif === 1
                            "
                        >
                            <template slot="title">
                                <span class="d-sm-none">Pills 2</span>
                                <span class="d-sm-block d-none">Pimpinan</span>
                            </template>
                            <b-table-simple
                                bordered
                                small
                                responsive
                                v-if="profil"
                            >
                                <b-th colspan="5">
                                    <h4>IDENTITAS PIMPINAN PERUSAHAAN</h4>
                                </b-th>
                                <b-tr>
                                    <b-td width="200px">Nama</b-td>
                                    <b-td class="font-weight-bold">
                                        <span v-if="profil">
                                            {{ profil.pimpinan.nama }}
                                        </span>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>NIK</b-td>
                                    <b-td
                                        colspan="4"
                                        class="font-weight-bold"
                                        >{{ profil.pimpinan.nik }}</b-td
                                    >
                                </b-tr>
                                <b-tr>
                                    <b-td>Jabatan</b-td>
                                    <b-td colspan="4">{{
                                        profil.pimpinan.jabatan
                                    }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>Nomor Handphone</b-td>
                                    <b-td colspan="4">{{
                                        profil.pimpinan.nomp
                                    }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>Nomor Telepon</b-td>
                                    <b-td colspan="4">{{
                                        profil.pimpinan.notelepon
                                    }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>Email</b-td>
                                    <b-td colspan="4">
                                        {{ profil.pimpinan.email }}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td width="150px">Alamat</b-td>
                                    <b-td colspan="5">
                                        {{ profil.pimpinan.alamat }}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td colspan="6" height="20px"> </b-td>
                                </b-tr>
                                <b-th colspan="6">
                                    <h4>SURAT KETETAPAN</h4>
                                </b-th>
                                <b-tr>
                                    <b-td>
                                        Nomor SK
                                    </b-td>
                                    <b-td class="font-weight-bold">
                                        {{ profil.pimpinan.no_sk }}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        Tanggal SK
                                    </b-td>
                                    <b-td>
                                        {{ profil.pimpinan.tgl_sk }}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        Status
                                    </b-td>
                                    <b-td>
                                        <div
                                            v-if="profil.pimpinan.aktif < 1"
                                            class="label label-danger"
                                            style="font-size: 11px;"
                                        >
                                            Non Aktif
                                        </div>
                                        <div
                                            v-else
                                            class="label label-success"
                                            style="font-size: 11px;"
                                        >
                                            Aktif
                                        </div>
                                    </b-td>
                                </b-tr>
                            </b-table-simple>
                        </b-tab>
                        <b-tab
                            v-if="
                                profil.jenis < 41 &&
                                    profil.penanggung_jawab.aktif === 1
                            "
                        >
                            <template slot="title">
                                <span class="d-sm-none">Pills 3</span>
                                <span class="d-sm-block d-none"
                                    >Penanggung Jawab</span
                                >
                            </template>
                            <b-table-simple
                                bordered
                                small
                                responsive
                                v-if="profil"
                            >
                                <b-th colspan="5">
                                    <h4>IDENTITAS PENANGGUNG JAWAB</h4>
                                </b-th>
                                <b-tr>
                                    <b-td width="200px">Nama</b-td>
                                    <b-td class="font-weight-bold">
                                        <span v-if="profil">
                                            {{ profil.penanggung_jawab.nama }}
                                        </span>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>NIK</b-td>
                                    <b-td
                                        colspan="4"
                                        class="font-weight-bold"
                                        >{{ profil.penanggung_jawab.nik }}</b-td
                                    >
                                </b-tr>
                                <b-tr>
                                    <b-td>Jabatan</b-td>
                                    <b-td colspan="4">{{
                                        profil.penanggung_jawab.jabatan
                                    }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>Nomor Handphone</b-td>
                                    <b-td colspan="4">{{
                                        profil.penanggung_jawab.nomp
                                    }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>Nomor Telepon</b-td>
                                    <b-td colspan="4">{{
                                        profil.penanggung_jawab.notelepon
                                    }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>Email</b-td>
                                    <b-td colspan="4">
                                        {{ profil.penanggung_jawab.email }}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td width="150px">Alamat</b-td>
                                    <b-td colspan="5">
                                        {{ profil.penanggung_jawab.alamat }}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td colspan="6" height="20px"> </b-td>
                                </b-tr>
                                <b-th colspan="6">
                                    <h4>SURAT KETETAPAN</h4>
                                </b-th>
                                <b-tr>
                                    <b-td>
                                        Nomor SK
                                    </b-td>
                                    <b-td class="font-weight-bold">
                                        {{ profil.penanggung_jawab.no_sk }}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        Tanggal SK
                                    </b-td>
                                    <b-td>
                                        {{ profil.penanggung_jawab.tgl_sk }}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        Status
                                    </b-td>
                                    <b-td>
                                        <div
                                            v-if="
                                                profil.penanggung_jawab.aktif <
                                                    1
                                            "
                                            class="label label-danger"
                                            style="font-size: 11px;"
                                        >
                                            Non Aktif
                                        </div>
                                        <div
                                            v-else
                                            class="label label-success"
                                            style="font-size: 11px;"
                                        >
                                            Aktif
                                        </div>
                                    </b-td>
                                </b-tr>
                            </b-table-simple>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>
            <div class="py-2" v-if="profil">
                <b-card v-if="profil.jenis < 41">
                    <div class="py-2">
                        <span class="h4">LIST WAKIL NPWPD</span>
                        <span class="float-right">
                            <span class="px-2">
                                <b-button
                                    variant="primary"
                                    size="sm"
                                    @click="create"
                                >
                                    <i class="fa fa-plus"></i> Tambah Wakil
                                </b-button>
                            </span>
                            <span>
                                <b-button
                                    variant="info"
                                    size="sm"
                                    @click="this.reload"
                                >
                                    <i class="fa fa-redo-alt"></i> Muat Ulang
                                </b-button>
                            </span>
                        </span>
                    </div>

                    <div class="py-2">
                        <!-- Data Table & Pagination -->
                        <b-table
                            show-empty
                            small
                            id="masTable"
                            ref="masTable"
                            head-variant="dark"
                            :hover="true"
                            :busy.sync="isBusy"
                            :items="myGetData"
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :filter="filter"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            responsive
                            bordered
                        >
                            <template #cell(index)="data">
                                {{ data.index + 1 }}
                            </template>
                            <template v-slot:table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>Loading...</strong>
                                </div>
                            </template>

                            <template v-slot:table-colgroup="scope">
                                <col
                                    v-for="field in scope.fields"
                                    :key="field.key"
                                    :style="{
                                        width:
                                            field.key === 'actions'
                                                ? '120px'
                                                : '',
                                    }"
                                />
                            </template>

                            <template #head()="data">
                                <span style="white-space: pre;">{{
                                    data.label
                                }}</span>
                            </template>

                            <template #cell(status_sk)="data">
                                <div v-if="data.item.status_sk === 1">
                                    Pimpinan
                                </div>
                                <div v-else>
                                    Penanggung Jawab
                                </div>
                            </template>

                            <template #cell(telp)="data">
                                email: {{ data.item.email }} <br />
                                No. Hp: {{ data.item.nomp }} <br />
                                No. Telepon: {{ data.item.notelepon }}
                            </template>

                            <template #cell(aktif)="data">
                                <div
                                    v-if="data.item.aktif === 1"
                                    class="label label-success"
                                >
                                    Aktif
                                </div>
                                <div v-else class="label label-danger">
                                    Non Aktif
                                </div>
                            </template>

                            <template #cell(sk)="data">
                                <div>
                                    <b-button variant="primary" block size="sm" @click="showPdf(data.item.dok_sk)">
                                        <i class="fa fa-file-pdf"></i> Lihat SK
                                    </b-button>
                                </div>
                            </template>

                            <!-- button actions -->
                            <template v-slot:cell(actions)="data">
                                <b-dropdown
                                    split
                                    class="m-r-2 d-flex align-item-center"
                                    size="sm"
                                    variant="info"
                                >
                                    <template #button-content>
                                        <i class="fa fa-cogs"></i> Aksi
                                    </template>
                                    <b-dropdown-item
                                        @click="lihat(data.item.id)"
                                        ><i class="fa fa-eye"></i>
                                        Lihat</b-dropdown-item
                                    >
                                    <b-dropdown-item @click="edit(data.item.id)"
                                        ><i class="fa fa-edit"></i>
                                        Edit</b-dropdown-item
                                    >
                                    <b-dropdown-item
                                        @click="
                                            swalNotification(
                                                'error',
                                                data.item.id
                                            )
                                        "
                                        v-if="data.item.aktif === 0"
                                        ><i class="fa fa-trash-alt"></i>
                                        Hapus</b-dropdown-item
                                    >
                                </b-dropdown>
                            </template>
                            <!-- end button actions -->
                        </b-table>
                        <div class="divider"></div>

                        <!-- pagination section -->
                        <b-row>
                            <div class="divider"></div>
                            <b-col md="6" class="my-0">
                                <b-form-group
                                    :label-cols="2"
                                    label="Per page"
                                    class="mb-0"
                                >
                                    <b-form inline>
                                        <b-form-select
                                            :options="pageOptions"
                                            style="width: 100px;"
                                            v-model="perPage"
                                        />
                                        <label class="ml-1 mr-1">/</label>
                                        <b-form-input
                                            :disabled="true"
                                            v-model="totalRows"
                                            style="width: 100px;"
                                            class="text-right"
                                        />
                                        <label class="ml-1">Rows</label>
                                    </b-form>
                                </b-form-group>
                            </b-col>
                            <b-col md="6" class="my-0">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="md"
                                    class="my-0 "
                                ></b-pagination>
                            </b-col>
                        </b-row>
                        <!-- end pagination section -->

                        <!-- end Data Table & Pagination -->
                    </div>
                </b-card>
            </div>
        </panel>
        <!-- end Panel-->
        <div v-else>
            <b-alert variant="warning" show>
                <h2 class="text-center peringatan">
                    Anda Belum Registrasi Data Wajib Pajak
                </h2>
                <p class="text-center">
                    Klik di sini untuk
                    <router-link to="/dashboard" class="font-weight-bold"
                        >Registrasi</router-link
                    >
                </p>
            </b-alert>
        </div>
        <!-- Modal -->
        <b-modal
            title="Detail Wakil NPWPD"
            v-model="showModalForm2"
            id="modal2"
            hide-footer
            no-close-on-backdrop
            size="lg"
        >
            <b-table-simple bordered small responsive v-if="profil">
                <b-th colspan="5">
                    <h4 v-if="form.status_sk === 1">IDENTITAS PIMPINAN PERUSAHAAN</h4>
                    <h4 v-else>IDENTITAS PENANGGUNG JAWAB PERUSAHAAN</h4>
                </b-th>
                <b-tr>
                    <b-td width="200px">Nama</b-td>
                    <b-td class="font-weight-bold">
                        <span v-if="profil">
                            {{ form.nama }}
                        </span>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td>NIK</b-td>
                    <b-td colspan="4" class="font-weight-bold">
                        {{ form.nik }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td>Jabatan</b-td>
                    <b-td colspan="4">
                        {{ form.jabatan }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td>Nomor Handphone</b-td>
                    <b-td colspan="4">
                        {{ form.nomp }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td>Nomor Telepon</b-td>
                    <b-td colspan="4">
                        {{ form.notelepon }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td>Email</b-td>
                    <b-td colspan="4">
                        {{ form.email }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td width="150px">Alamat</b-td>
                    <b-td colspan="5">
                        {{ form.alamat }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td colspan="6" height="20px"> </b-td>
                </b-tr>
                <b-th colspan="6">
                    <h4>SURAT KETETAPAN</h4>
                </b-th>
                <b-tr>
                    <b-td>
                        Nomor SK
                    </b-td>
                    <b-td class="font-weight-bold">
                        {{ form.no_sk }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td>
                        Tanggal SK
                    </b-td>
                    <b-td>
                        {{ form.tgl_sk }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td>
                        Status
                    </b-td>
                    <b-td>
                        <div
                            v-if="form.aktif < 1"
                            class="label label-danger"
                            style="font-size: 11px;"
                        >
                            Non Aktif
                        </div>
                        <div
                            v-else
                            class="label label-success"
                            style="font-size: 11px;"
                        >
                            Aktif
                        </div>
                    </b-td>
                </b-tr>
            </b-table-simple>
        </b-modal>
        <!-- Modal -->
        <b-modal
            :title="editMode ? 'Edit Waikil NPWPD' : 'Tambah Wakil NPWPD'"
            v-model="showModalForm"
            id="modal"
            hide-footer
            no-close-on-backdrop
            size="lg"
        >
            <b-form
                @submit.prevent="editMode ? update(wakilId) : store()"
                @keydown="form.onKeydown($event)"
                autocomplete="off"
            >
                <div class="form-group row mb-1">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >NIK
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <input
                            type="text"
                            v-model="form.nik"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.hasOwnProperty('nik'),
                            }"
                            placeholder="Masukkan NIK"
                        />
                        <em
                            v-if="errors.hasOwnProperty('nik')"
                            class="form-text text-danger"
                            >{{ errors.nik[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Nama
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <input
                            type="text"
                            v-model="form.nama"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.hasOwnProperty('nama'),
                            }"
                            placeholder="Masukkan Nama"
                        />
                        <em
                            v-if="errors.hasOwnProperty('nama')"
                            class="form-text text-danger"
                            >{{ errors.nama[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Jabatan
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <input
                            type="text"
                            v-model="form.jabatan"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.hasOwnProperty('jabatan'),
                            }"
                            placeholder="Masukkan Jabatan"
                        />
                        <em
                            v-if="errors.hasOwnProperty('jabatan')"
                            class="form-text text-danger"
                            >{{ errors.jabatan[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Status SK
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-5">
                        <v-select
                            label="nama"
                            :options="statusOptions"
                            :reduce="(nama) => nama.id"
                            v-model="form.status_sk"
                            placeholder="Pilih Status SK"
                        ></v-select>
                        <em
                            v-if="errors.hasOwnProperty('status_sk')"
                            class="form-text text-danger"
                            >{{ errors.status_sk[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >No. Surat Ketetapan
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <input
                            type="text"
                            v-model="form.no_sk"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.hasOwnProperty('no_sk'),
                            }"
                            placeholder="Masukkan Nomor Surat Ketetapan"
                        />
                        <em
                            v-if="errors.hasOwnProperty('no_sk')"
                            class="form-text text-danger"
                            >{{ errors.no_sk[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Tgl. Surat Ketetapan
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-5">
                        <div class="input-group">
                            <date-picker
                                id="dateTimeInput"
                                v-model="form.tgl_sk"
                                :config="options"
                                :class="{
                                    'form-control': true,
                                    'is-invalid': errors.hasOwnProperty(
                                        'tgl_sk'
                                    ),
                                }"
                                placeholder="-Pilih Tanggal-"
                            ></date-picker>
                            <div class="input-group-append">
                                <label
                                    for="dateTimeInput"
                                    class="input-group-text"
                                >
                                    <i class="fa fa-calendar"></i>
                                </label>
                            </div>
                        </div>
                        <em
                            v-if="errors.hasOwnProperty('tgl_sk')"
                            class="form-text text-danger"
                            >{{ errors.tgl_sk[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Status
                    </label>
                    <div class="col-md-5">
                        <v-select
                            label="nama"
                            :options="statusAktifOptions"
                            :reduce="(nama) => nama.id"
                            v-model="form.aktif"
                            placeholder="Pilih Status Aktif"
                        ></v-select>
                        <em
                            v-if="errors.hasOwnProperty('aktif')"
                            class="form-text text-danger"
                            >{{ errors.aktif[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >No. Handphone
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <input
                            type="text"
                            v-model="form.nomp"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.hasOwnProperty('nomp'),
                            }"
                            placeholder="Masukkan Nomor Handphone"
                        />
                        <em
                            v-if="errors.hasOwnProperty('nomp')"
                            class="form-text text-danger"
                            >{{ errors.nomp[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >No. Telepon
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <input
                            type="text"
                            v-model="form.notelepon"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.hasOwnProperty(
                                    'notelepon'
                                ),
                            }"
                            placeholder="Masukkan Nomor Telepon"
                        />
                        <em
                            v-if="errors.hasOwnProperty('notelepon')"
                            class="form-text text-danger"
                            >{{ errors.notelepon[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Email
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <input
                            type="email"
                            v-model="form.email"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.hasOwnProperty('email'),
                            }"
                            placeholder="Masukkan Alamat Email"
                        />
                        <em
                            v-if="errors.hasOwnProperty('email')"
                            class="form-text text-danger"
                            >{{ errors.email[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Upload Dokumen SK
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <b-form-file
                            v-model="form.dok_sk"
                            plain
                            @change="handleFile"
                        ></b-form-file>
                        <em
                            v-if="errors.hasOwnProperty('dok_sk')"
                            class="form-text text-danger"
                        >
                            <div
                                v-for="(item, index) in errors.dok_sk"
                                :key="index"
                            >
                                {{ item }}
                            </div>
                        </em>
                    </div>
                </div>
                <div class="py-2">
                    <h4>ALAMAT</h4>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Alamat
                    </label>
                    <div class="col-md-8">
                        <input
                            type="text"
                            v-model="form.alamat"
                            class="form-control"
                            placeholder="Masukkan Alamat"
                        />
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Provinsi
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-5">
                        <v-select
                            label="nama"
                            :options="provinsiOptions"
                            :reduce="(nama) => nama.id"
                            v-model="input.provinsi"
                            @input="onProvinsi(input.provinsi)"
                            placeholder="Pilih Provinsi"
                        >
                        </v-select>
                        <em
                            v-if="errors.hasOwnProperty('wilayah_kelurahan_id')"
                            class="form-text text-danger"
                            >{{ errors.wilayah_kelurahan_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Kota/Kabupaten
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-5">
                        <v-select
                            label="nama"
                            :options="kotaOptions"
                            v-model="input.kabkota"
                            :reduce="(nama) => nama.id"
                            @input="onKabKota(input.kabkota)"
                            placeholder="Pilih Kabupaten/Kota"
                            class="bg-white"
                        ></v-select>
                        <em
                            v-if="errors.hasOwnProperty('wilayah_kelurahan_id')"
                            class="form-text text-danger"
                            >{{ errors.wilayah_kelurahan_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Kecamatan
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-5">
                        <v-select
                            label="nama"
                            :options="kecamatanOptions"
                            :reduce="(nama) => nama.id"
                            v-model="input.kecamatan"
                            @input="onKecamatan(input.kecamatan)"
                            placeholder="Pilih Kecamatan"
                        ></v-select>
                        <em
                            v-if="errors.hasOwnProperty('wilayah_kelurahan_id')"
                            class="form-text text-danger"
                            >{{ errors.wilayah_kelurahan_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Kelurahan
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-5">
                        <v-select
                            label="nama"
                            :options="kelurahanOptions"
                            :reduce="(nama) => nama.id"
                            v-model="form.wilayah_kelurahan_id"
                            placeholder="Pilih Kelurahan"
                            class="bg-white"
                        ></v-select>
                        <em
                            v-if="errors.hasOwnProperty('wilayah_kelurahan_id')"
                            class="form-text text-danger"
                            >{{ errors.wilayah_kelurahan_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Kodepos
                    </label>
                    <div class="col-md-5">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.hasOwnProperty('kodepos'),
                            }"
                            v-model="form.kodepos"
                            placeholder="Masukkan Kodepos"
                        />
                        <em
                            v-if="errors.hasOwnProperty('kodepos')"
                            class="form-text text-danger"
                            >{{ errors.kodepos[0] }}</em
                        >
                    </div>
                </div>
                <hr />
                <div>
                    <span>
                        <b-button variant="primary" type="submit" class="px-4">
                            <span>
                                <span v-if="isLoading" class="px-1">
                                    <b-spinner
                                        variant="primary"
                                        small
                                    ></b-spinner>
                                </span>
                                <span v-else class="px-1">
                                    <i class="fa fa-save"></i>
                                </span>
                                Simpan
                            </span>
                        </b-button>
                    </span>
                    <span class="float-right">
                        <b-button variant="secondary" v-show="isReset">
                            <i class="fa fa-redo-alt"></i> Reset
                        </b-button>
                    </span>
                </div>
            </b-form>
        </b-modal>
        <!-- Modal pdf -->
        <b-modal id="modal-1" size="xl" v-model="showModalData" hide-footer hide-header title="Surat Ketetapan" no-close-on-backdrop>
            <b-row>
				<b-col>
					<h5>Detail SURAT KETETAPAN</h5>
				</b-col>
				<b-col>
					<span class="float-right pb-2">
						<!-- <b-button variant="outline-dark" @click="closeModal"><i class="fa fa-times"></i></b-button> -->
						<i class="fa fa-times fa-2x tutup" @click="closeModal" v-b-tooltip.hover title="Tutup" style="cursor: pointer;"></i>
					</span>
				</b-col>
			</b-row>
            <div v-if="notFound">
                <b-alert variant="danger" show class="text-center">
                    Data Surat Ketetapan tidak dapat ditemukan.
                </b-alert>
            </div>
			<div v-else>
				<iframe :src="pdfUrl" frameborder="1" height="1100" width="100%"></iframe>
			</div>
        </b-modal>  
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import Logo from "@/assets/images/logoSumut.png";
import PageOptions from "@/config/PageOptions.vue";
import HRetribusi from "@/components/header/HRetribusi.vue";
import axios from "axios";

export default {
    data() {
        return {
            form: new Form({
                nik: "",
                npwpd_id: "",
                nama: "",
                nomp: "",
                notelepon: "",
                email: "",
                alamat: "",
                wilayah_kelurahan_id: "",
                kodepos: "",
                jabatan: "",
                no_sk: "",
                tgl_sk: "",
                dok_sk: null,
                status_sk: "",
                aktif: "",
            }),
            input: {
                provinsi: "",
                kabkota: "",
                kecamatan: "",
            },
            bentukBadanOptions: [],
            provinsiOptions: [],
            kotaOptions: [],
            kecamatanOptions: [],
            kelurahanOptions: [],
            isLoading: false,
            editMode: false,
            errors: [],
            options: {
                format: "YYYY-MM-DD",
                useCurrent: false,
            },
            statusOptions: [
                {
                    id: 1,
                    nama: "Pimpinan",
                },
                {
                    id: 2,
                    nama: "Penanggung Jawab",
                },
            ],
            statusAktifOptions: [
                {
                    id: 0,
                    nama: "Non Aktf",
                },
                {
                    id: 1,
                    nama: "Aktif",
                },
            ],
            imageLogo: Logo,
            role: localStorage.getItem("roles"),
            user: JSON.parse(localStorage.getItem("user")),
            profil: null,
            animationInfinite: {
                classes: "flash",
                duration: 7000,
                iteration: "infinite",
            },
            fields: [
                {
                    key: "index",
                    label: "No.",
                },
                {
                    key: "nama",
                    label: "Nama",
                    sortable: true,
                },
                {
                    key: "jabatan",
                    label: "Jabatan",
                    sortable: true,
                },
                {
                    key: "status_sk",
                    label: "Status SK",
                    sortable: true,
                },
                {
                    key: "alamat",
                    label: "Alamat",
                    sortable: true,
                },
                {
                    key: "telp",
                    label: "Telepon & Email",
                },
                {
                    key: "aktif",
                    label: "Status",
                },
                {
                    key: "sk",
                    label: "Surat Ketetapan",
                },
                {
                    key: "actions",
                    label: "Opsi",
                },
            ],
            // Tools
            isBusy: false,
            sortBy: null,
            sortDesc: false,
            filter: null,
            filterBy: "all",
            pageOptions: [5, 10, 15, 25],
            perPage: 10,
            currentPage: 1,
            totalRows: 0,
            showModalForm: false,
            showModalForm2: false,
            isReset: true,
            wakilId: "",
            pdfUrl: '',
            showModalData: false,
            notFound: false
        };
    },
    components: {
        HRetribusi,
    },
    computed: {
        fieldOptions() {
            // Create an options list from our fields
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => {
                    return {
                        text: f.label,
                        value: f.key,
                    };
                });
        },
    },
    created() {
        PageOptions.pageEmpty = false;
        document.body.className = "";
    },
    mounted() {
        this.getProvinsi();
        this.getNpwpd();
    },
    methods: {
        handleFile(event) {
            const file = event.target.files[0];
            this.form.dok_sk = file;
        },
        // store data
        store() {
            this.isLoading = true;
            this.form.npwpd_id = this.user.npwpd_id;
            const formData = new FormData();
            formData.append("dok_sk", this.form.dok_sk);
            formData.append("nik", this.form.nik);
            formData.append("npwpd_id", this.form.npwpd_id);
            formData.append("nama", this.form.nama);
            formData.append("nomp", this.form.nomp);
            formData.append("notelepon", this.form.notelepon);
            formData.append("email", this.form.email);
            formData.append("alamat", this.form.alamat);
            formData.append(
                "wilayah_kelurahan_id",
                this.form.wilayah_kelurahan_id
            );
            formData.append("kodepos", this.form.kodepos);
            formData.append("jabatan", this.form.jabatan);
            formData.append("no_sk", this.form.no_sk);
            formData.append("tgl_sk", this.form.tgl_sk);
            formData.append("status_sk", this.form.status_sk);
            axios
                .post("/api/wajib-pajak/npwpd-wakil", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    this.isLoading = false;
                    this.editMode = true;
                    this.isReset = false;
                    this.errors = [];
                    this.reset();
                    this.reload();
                    this.$swal({
                        icon: "success",
                        title: "Data berhasil ditambah",
                        text: "Silahkan Cek Data pada Tabel.",
                        type: "success",
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        confirmButtonText: "OKE",
                        showCloseButton: true,
                        timer: 5000,
                    });
                    /*  this.form.reset()
                this.kodeNama= ''
                this.message = false */
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.editMode = false;
                    this.isReset = true;
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    } else if (error.response.status == 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                    console.log(error);
                });
        },
        // update data
        update(id) {
            this.isLoading = true;
            this.form.npwpd_id = this.user.npwpd_id;
            const formData = new FormData();
            formData.append("dok_sk", this.form.dok_sk);
            formData.append("nik", this.form.nik);
            formData.append("npwpd_id", this.form.npwpd_id);
            formData.append("nama", this.form.nama);
            formData.append("nomp", this.form.nomp);
            formData.append("notelepon", this.form.notelepon);
            formData.append("email", this.form.email);
            formData.append("alamat", this.form.alamat);
            formData.append(
                "wilayah_kelurahan_id",
                this.form.wilayah_kelurahan_id
            );
            formData.append("kodepos", this.form.kodepos);
            formData.append("jabatan", this.form.jabatan);
            formData.append("no_sk", this.form.no_sk);
            formData.append("tgl_sk", this.form.tgl_sk);
            formData.append("status_sk", this.form.status_sk);
            formData.append("_method", "PATCH");
            axios
                .post("/api/wajib-pajak/npwpd-wakil/" + id, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    this.isLoading = false;
                    this.editMode = true;
                    this.isReset = false;
                    this.errors = [];
                    this.reset();
                    this.reload();
                    this.$swal({
                        icon: "success",
                        title: "Data berhasil dirubah",
                        text: "Silahkan periksa kembali data anda.",
                        type: "success",
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        confirmButtonText: "OKE",
                        showCloseButton: true,
                        timer: 5000,
                    });
                    this.$router.push({ name: "ListWakilNpwpd" });
                    /*  this.form.reset()
                this.kodeNama= ''
                this.message = false */
                })
                .catch((error) => {
                    this.isLoading = false;
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                        this.message = error.response.data.message;
                    } else if (error.response.status == 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                    console.log(error);
                });
        },
        myGetData(ctx) {
            // ctx =
            // berasal dari tag <b-table></b-table>
            //this.$Progress.start();
            this.isBusy = true;
            let promise = axios.get("/api/wajib-pajak/npwpd-wakil", {
                params: {
                    page: ctx.currentPage,
                    perpage: ctx.perPage,
                    sortby: ctx.sortBy,
                    sortdesc: ctx.sortDesc,
                    filter: ctx.filter,
                    filterby: this.filterBy,
                },
            });
            return promise
                .then((response) => {
                    const items = response.data.data;
                    // Data Pagination
                    // configPagination(response.data.meta);
                    this.totalRows = response.data.meta.total;
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.finish();
                    this.isBusy = false;
                    // console.log(items);
                    return items;
                })
                .catch((error) => {
                    // console.error(error);
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.fail();
                    this.isBusy = false;
                    if (error.response.status == 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                    // Returning an empty array, allows table to correctly handle
                    // internal busy state in case of error
                    return [];
                });
        },
        reload() {
            // with id="masTable" in tag b-table
            // this.$root.$emit('bv::refresh::table', 'masTable')
            // with property ref="masTable" in tag b-table
            this.$refs.masTable.refresh();
        },
        changeFilterBy() {
            if (this.filter) {
                this.$refs.masTable.refresh();
            }
        },

        getNpwpd() {
            axios
                .get("/api/wajib-pajak/npwpd/" + this.user.npwpd_id)
                .then((response) => {
                    this.profil = response.data.data;
                    // console.log(this.profil)
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status == 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                    // else if (error.response.status == 404) {
                    //     this.$swal({
                    //         icon: "error",
                    //         title: "Terjadi Masalah",
                    //         html:
                    //             "Silahkan hubungi bagian Maintenance. <br> ERROR: " +
                    //             error.response.data.status.message,
                    //         type: "error",
                    //         showConfirmButton: true,
                    //         confirmButtonClass: "btn btn-primary",
                    //         confirmButtonText: "OKE",
                    //         showCloseButton: true,
                    //         timer: 5000,
                    //     });
                    // }
                });
        },
        // data npwpd wakil
        getWakil(id) {
            axios
                .get("/api/wajib-pajak/npwpd-wakil/" + id)
                .then((response) => {
                    const items = response.data.data;
                    this.form.fill(items);
                    this.input.provinsi =
                        items.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota.wilayah_provinsi_id;
                    this.getKabupaten(this.input.provinsi);
                    this.input.kabkota =
                        items.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota_id;
                    this.getKecamatan(this.input.kabkota);
                    this.input.kecamatan =
                        items.wilayah_kelurahan.wilayah_kecamatan_id;
                    this.getKelurahan(this.input.kecamatan);
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status == 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                });
        },
        //Wilayah
        // saat provinsi di input
        onProvinsi(id) {
            if (this.input.provinsi === null) {
                this.kotaOptions = [];
                this.input.kota = null;
                this.kecamatanOptions = [];
                this.input.kecamatan = null;
            } else {
                this.getKabupaten(id);
            }
        },
        // saat kab./kota di input
        onKabKota(id) {
            if (this.input.kota === null) {
                this.kecamatanOptions = [];
                this.input.kecamatan = null;
            } else {
                this.getKecamatan(id);
            }
        },

        // saat kab./kota di input
        onKecamatan(id) {
            if (this.input.kecamatan === null) {
                this.kecamatanOptions = [];
                this.input.kecamatan = null;
            } else {
                this.getKelurahan(id);
            }
        },

        // Provinsi
        getProvinsi() {
            axios
                .get("/api/data-induk/wilayah-indonesia/provinsi/getoption")
                .then((response) => {
                    this.provinsiOptions = response.data.data;
                    /* console.log(this.provinsiOptions) */
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                });
        },
        // Kabupaten
        getKabupaten(id) {
            axios
                .get(
                    "/api/data-induk/wilayah-indonesia/kabkota/getoption/provinsi-" +
                        id
                )
                .then((response) => {
                    /* console.log(response.data.data) */
                    this.kotaOptions = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                });
        },
        // Kecamatan
        getKecamatan(id) {
            axios
                .get(
                    "/api/data-induk/wilayah-indonesia/kecamatan/getoption/kabkota-" +
                        id
                )
                .then((response) => {
                    /*  console.log(response.data.data) */
                    this.kecamatanOptions = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                });
        },

        // Kelurahan
        getKelurahan(id) {
            axios
                .get(
                    "/api/data-induk/wilayah-indonesia/kelurahan/getoption/kecamatan-" +
                        id
                )
                .then((response) => {
                    /* console.log(response.data.data) */
                    this.kelurahanOptions = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                });
        },
        // reset form
        reset() {
            this.form.reset();
            this.input.provinsi = null;
            this.input.kabkota = null;
            this.input.kecamatan = null;
            this.errors = [];
        },
        // show modal
        create() {
            this.reset();
            this.editMode = false;
            this.isReset = true;
            this.$root.$emit("bv::show::modal", "modal");
            this.showModalForm == true;
        },
        // edit
        edit(id) {
            this.reset();
            this.editMode = true;
            this.isReset = false;
            this.getWakil(id);
            this.wakilId = id;
            this.form.dok_sk = null
            this.$root.$emit("bv::show::modal", "modal");
            this.showModalForm == true;
        },
        // lihat
        lihat(id) {
            this.reset();
            this.getWakil(id);
            this.$root.$emit("bv::show::modal", "modal2");
            this.showModalForm2 == true;
        },
        swalNotification(swalType, id) {
            var btnClass = swalType == "error" ? "danger" : swalType;
            btnClass = swalType == "question" ? "primary" : btnClass;
            this.$swal({
                title: "Anda yakin ?",
                text: "Anda tidak akan bisa mengembalikan data!",
                type: swalType,
                showCancelButton: true,
                buttonsStyling: false,
                confirmButtonText: "Hapus",
                cancelButtonText: "Cancel",
                confirmButtonClass: "btn m-r-5 btn-" + btnClass + "",
                cancelButtonClass: "btn btn-default",
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .delete("/api/wajib-pajak/npwpd-wakil/" + id)
                        .then((response) => {
                            // console.log(response.data);
                            this.$swal({
                                title: "Data Berhasil Dihapus!",
                                icon: "success",
                                type: "success",
                                showCloseButton: true,
                                showConfirmButton: true,
                                confirmButtonClass: "btn btn-primary",
                                confirmButtonText: "OKE",
                                timer: 5000,
                            });
                            this.reload();
                        }).catch(error => {
                            if (error.response.status == 401) {
                                localStorage.removeItem("EP_token");
                                this.$router.push({ name: "Login" });
                            }
                        });
                }
            });
        },
        showPdf (id) {
            this.$root.$emit("bv::show::modal", "modal-1")
            this.showModalData = true
            this.getPdf(id)
        },
        // close modal
        closeModal () {
            this.$root.$emit("bv::hide::modal", 'modal-1');
            this.showModalData = false
            this.pdfUrl = ''
        },
        // pdf
        getPdf (value) {
            axios.get('/api/wajib-pajak/npwpd-wakil/view-pdf/doksk-' + value, {responseType: 'arraybuffer'}).then(response => {
                this.pdfUrl = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
            }).catch(error => {
                console.log(error)
                if (error.response.status === 404) {
                    this.notFound = true
                } else if (error.response.status == 401) {
                    localStorage.removeItem("EP_token");
                    this.$router.push({ name: "Login" });
                }
            })
        },
    },
};
</script>

<style scoped>
.peringatan {
    animation-name: example;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes example {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.07);
    }
}

.float-right:hover .cust-shadow {
    box-shadow: 3px 3px 7px rgba(83, 82, 82, 0.897);
    transition: all 0.3s linear;
}
</style>
